<template>
  <button class="btn mt-3 px-10 btn-primary" @click="handleCancelScheduledCall">Cancel Scheduled call</button>
  <TransitionRoot appear :show="isRevealed" as="template">
    <Dialog as="div" @close="cancel" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-slate-400/55 dark:bg-slate-900/65 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-red-100 p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                Cancel scheduled call
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-black">
                  Are you sure you want to cancel the scheduled call?
                </p>
              </div>

              <div class="mt-4 float-right">
                <button type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-red-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                  @click="confirm(true)">
                  Yes
                </button>
                <button type="button"
                  class="inline-flex ml-3 justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-green-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                  @click="confirm(false)">
                  No
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useMyCandidatesStore } from "~/store/candidate";
const candidateStore = useMyCandidatesStore();
import { useMyAppStore } from "~/store/app";
import { useConfirmDialog } from "@vueuse/core";
interface Props {
  call_id?: string,
  candidate_id?: string
}

const props = withDefaults(defineProps<Props>(), {
  call_id: "",
  candidate_id: ""
});
const appStore = useMyAppStore()

const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();

const handleCancelScheduledCall = async () => {
  const { data } = await reveal();
  if (!data) {
    return;
  }
  const res = await candidateStore.cancelScheduledCall(props.call_id);
  console.log(props.candidate_id)
  const route = useRoute();
  const id = route.params.id as string;
  await candidateStore.findOneCandidate(id);
  
  if (res.status !== 200) {
    appStore.error({
      text: res.error || "Error cancelling scheduled call",
    })
  }
};
</script>
