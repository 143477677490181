// use datefns to format date in in the form of Jul 11 2021 12:00 AM

import { format } from 'date-fns-tz';

export const formatDate = (date: Date): string => {
  return format(date, 'MMM dd yyyy hh:mm a', { timeZone: 'Asia/Kolkata' });
}

// return  date in this form`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

export const formatDateForInput = (date: Date): string => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
}