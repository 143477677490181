<script lang="ts" setup>
const toast = useToast();
import { type Candidate } from "~/types/candidate";
import LiveCall from "~/components/candidate/LiveCall.vue";
import { useMyCandidatesStore } from "~/store/candidate";
import PlayPause from "../PlayPause.vue";
import WaveSurfer from "~/components/candidate/WaveSurfer.vue";
// get a single candidate
interface Props {
  candidate: Candidate;
}
const props = defineProps<Props>();

const evaluating = ref(false);

const candidate = ref(props.candidate);

const userInfo = computedAsync(
  async () => {
    const calls = [...props.candidate?.calls];
    const upComingCalls = [...props.candidate?.upcoming_calls];
    const callData = await Promise.all(
      calls.map(async (call) => {
        const response = (await useMyCandidatesStore().getAllCallDetails(call))
          .data;
        return response;
      })
    );
    const upComingCallsData = await Promise.all(
      upComingCalls.map(async (call) => {
        const response = (
          await useMyCandidatesStore().getUpcomingCallDetails(call)
        ).data;
        return response;
      })
    );
    return [
      ...callData
        .map((call) => ({ ...call }))
        .filter((call) => call?.callStatus !== "busy"),
      ...upComingCallsData.map((call) => ({ ...call, callStatus: "upcoming" })),
    ]
      .map((call, i) => {
      return {
        ...call,
        id: Math.random(),
        isLiveCall: call?.callStatus === "ringing" || call?.callStatus === "processed" || call?.callStatus === "in-progress",
        formattedTime: new Date(
        (call.createdTime || call.created_time) * 1000
        ).toLocaleString(),
      };
      })
      .sort((a, b) => {
      if (a.callStatus === "ringing" || a.callStatus === "in-progress") {
        return -1;
      } else if (a.callStatus === "upcoming") {
        return -1;
      } else if (
        a.callStatus === "completed" &&
        b.callStatus !== "upcoming"
      ) {
        return -1;
      } else {
        return 1;
      }
      });
  },
  null,
  evaluating
);
watch(userInfo, (newVal: any) => {
  if (newVal && newVal.length > 0) {
    selectedRecord.value = newVal[0];
  }
});
const formatDate = (date: number) => {
  return new Date(date).toLocaleString();
};
const selectedRecord = ref(null);
const currentPlayer = ref(null);
const isOpen = ref(false);

const playThisAudio = (id: number, record: any) => {
  selectedRecord.value = record;
  selectedRecord.value.order = id + 1;
};
const waveSurferOptions = {
  height: 340,
  progressColor: '#e03639',
  waveColor: '#e7e7e7',
  cursorColor: '#FFDDDD',
  barWidth: 2,
  mediaControls: false,
  backend: 'MediaElement',
  scrollParent:true,
  xhr: {
    mode: 'no-cors'
  }
}
const refWaveSurfer = ref(null);
const isPlaying = ref(false);

function togglePlayPause() {
  if (refWaveSurfer.value) {
    if (isPlaying.value) {
      refWaveSurfer.value.waveSurfer.pause();
    } else {
      refWaveSurfer.value.waveSurfer.play();
    }
    isPlaying.value = !isPlaying.value;
  }
}
</script>

<template>
  <main class="relative flex h-full w-full overflow-hidden transition-colors z-0"
    v-if="!evaluating && userInfo?.length > 0">
    <!-- lhs -->
    <section class="flex-shrink-0 overflow-x-hidden bg-base-300 max-md:!w-0">
      <div class="h-full w-[360px]">
        <div class="flex h-full min-h-0 flex-col">
          <div class="draggable relative h-full w-full flex-1 items-start border-white/20">
            <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
              <li v-for="(call, i) in userInfo" :key="i" 
                  class="py-4 hover:bg-gray-50 dark:hover:bg-gray-800 transition duration-150 ease-in-out cursor-pointer"
                  :class="{ 'bg-blue-50 dark:bg-blue-900': selectedRecord?.id === call?.id }"
                  @click="playThisAudio(i, call)">
                <div class="flex items-center space-x-4">
                  <div class="flex-shrink-0">
                    <PlayPause :isPlaying="currentPlayer === i" class="h-6 w-6 text-gray-400" />
                  </div>
                  <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 dark:text-white truncate">
                      {{ call?.callStatus }}
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400 truncate">
                      {{ formatDate(call?.scheduledTime) }}
                    </p>
                  </div>
                  <div>
                    <span v-if="call.isLiveCall" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100">
                      Live
                    </span>
                    <span v-else-if="call?.callStatus === 'upcoming'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100">
                      Upcoming
                    </span>
                    <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                      Completed
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- rhs -->
    <section class="relative flex h-full max-w-full flex-1 flex-col overflow-hidden px-8 py-8">
      
      <template v-if="selectedRecord?.callStatus === 'completed'">
        <div class="card bg-neutral text-neutral-content text-wrap px-4 py-6 h-full mb-3">
          <div class="badge badge-info">Call Summary</div>
          <p>{{ selectedRecord?.callSummary }}</p>
        </div>
        <!-- wave surfer logic -->
      <WaveSurfer :key="selectedRecord?.convAudio" ref="refWaveSurfer" :src="selectedRecord?.convAudio" :options="waveSurferOptions"/>
      <div class="flex justify-center items-center mt-2">
        <button @click="togglePlayPause" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center transition duration-300">
            <Icon :name="isPlaying ? 'uil:pause' : 'uil:play'" class="mr-2" />
            {{ isPlaying ? 'Pause' : 'Play' }}
          </button>
        </div>
        <div class="grid grid-flow-col gap-6 mt-11">
          <div
            class="card bg-neutral text-neutral-content text-wrap px-4 py-6 h-full max-h-96 overflow-y-hidden truncate">
            <div class="badge badge-secondary">Pros and cons</div>
            <p>{{ selectedRecord?.callProsCons }}</p>
          </div>
          <div class="card bg-neutral text-neutral-content text-wrap px-4 py-6 h-full">
            <div class="badge badge-success">Questions</div>
            <ul class="list-decimal px-3 py-2">
              <li v-for="(question, i) in selectedRecord?.callQues" :key="i">
                {{ question }}
              </li>
            </ul>
          </div>
        </div>
        
        <div class="divider my-7"></div>
        <h3 class="text-3xl"> Conversion log</h3>
        <template v-for="(chat, i) in selectedRecord?.chat_history_object_list" :key="i">
          <div class="chat chat-start">
            <div class="chat-image avatar">
              <div class="w-10 rounded-full">
                <img
                  alt="Tailwind CSS chat bubble component"
                  src="@/assets/img/Pi_logo.png" />
              </div>
            </div>
            <div class="chat-header">
              Sophia
            </div>
            <div class="chat-bubble">{{chat.ai_response}}</div>
          </div>
          <div class="chat chat-end">
            <div class="chat-image avatar">
              <div class="w-10 rounded-full">
                <img
                  alt="Tailwind CSS chat bubble component"
                  src="@/assets/img/Gemini_Generated_Image_mn8yhcmn8yhcmn8y.jpg" />
              </div>
            </div>
            <div class="chat-header">
              Candidate
            </div>
            <div class="chat-bubble">{{chat.user_response || "N/A"}}</div>
          </div>
        </template>
      </template>
      <!-- upcoming calls -->
      <div v-else-if="selectedRecord?.callStatus === 'upcoming'">
        <div class="card bg-neutral text-neutral-content text-wrap px-4 py-6 h-full">
          <div class="badge badge-success">Questions</div>
          <ul class="list-decimal px-3 py-2">
            <li v-for="(question, i) in selectedRecord?.task_info?.ques" :key="i">
              {{ question }}
            </li>
          </ul>
        </div>
        <div class="grid grid-flow-col gap-6">
          <section class="bg-base-200/90 rounded-lg shadow-sm px-8 py-6 mt-5">
            <h2 class="text-xl font-bold">ReSchedule this call</h2>
            <ScheduleCall :hash_id="candidate?.hash_id" />
          </section>
          <section class="bg-base-200/90 w-[340px] rounded-lg shadow-sm px-8 py-6 mt-5">
            <h2 class="text-xl font-bold">Call Schedule</h2>
            <h2 class="card-title">Scheduled Call Summary</h2>
            <p> Date : {{ formatDate(selectedRecord?.time_to_call) }}</p>
            <p> Timezone: {{ selectedRecord?.timezone }} </p>
            <CancelScheduledCall :call_id="selectedRecord?.call_id" :candidate_id="candidate?.hash_id"></CancelScheduledCall>
          </section>
        </div>
      </div>
      <div v-if="selectedRecord?.isLiveCall && selectedRecord?.callSid">
        <LiveCall :callId="selectedRecord?.callSid" />
      </div>
    </section>
  </main>
  <MazFullscreenLoader v-if="evaluating" @click="showLoader = false">
    <p>
      Loading...
    </p>
  </MazFullscreenLoader>
</template>

<style scoped>
.wave-menu {
  border-radius: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: ease 0.2s;
  position: relative;
}

.wave-menu li {
  list-style: none;
  height: 30px;
  width: 4px;
  border-radius: 10px;
  background: #545fe5;
  margin: 0 6px;
  padding: 0;
  animation-name: wave1;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: ease 0.2s;
}

.wave-menu:hover>li {
  background: #fff;
}

.wave-menu:hover {
  background: #545fe5;
}

.wave-menu li:nth-child(2) {
  animation-name: wave2;
  animation-delay: 0.2s;
}

.wave-menu li:nth-child(3) {
  animation-name: wave3;
  animation-delay: 0.23s;
  animation-duration: 0.4s;
}

.wave-menu li:nth-child(4) {
  animation-name: wave4;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
}

.wave-menu li:nth-child(5) {
  animation-delay: 0.5s;
}

.wave-menu li:nth-child(6) {
  animation-name: wave2;
  animation-duration: 0.5s;
}

.wave-menu li:nth-child(8) {
  animation-name: wave4;
  animation-delay: 0.4s;
  animation-duration: 0.25s;
}

.wave-menu li:nth-child(9) {
  animation-name: wave3;
  animation-delay: 0.15s;
}

@keyframes wave1 {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.5);
  }
}

@keyframes wave2 {
  from {
    transform: scaleY(0.3);
  }

  to {
    transform: scaleY(0.6);
  }
}

@keyframes wave3 {
  from {
    transform: scaleY(0.6);
  }

  to {
    transform: scaleY(0.8);
  }
}

@keyframes wave4 {
  from {
    transform: scaleY(0.2);
  }

  to {
    transform: scaleY(0.5);
  }
}
</style>
