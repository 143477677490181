<template>
  <div v-if="status === 'CONNECTING'">
    <div class="flex items-center justify-center p-4 bg-red-100 rounded-lg">
      <span class="text-red-600 font-semibold"> Connecting... </span>
    </div>
  </div>
  <div
    v-if="status === 'CLOSED'"
    class="flex items-center justify-center p-4 bg-red-100 rounded-lg"
  >
    <span class="text-red-600 font-semibold"> Status: Closed </span>
  </div>
  <div v-if="status === 'OPEN'">
    <div
      class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 rounded-md"
      role="alert"
    >
      <div class="flex items-center">
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <p class="font-bold">Live Chat in Progress</p>
      </div>
      <p class="text-sm mt-2">
        The conversation is currently ongoing. New messages will appear in real-time
        below.
      </p>
    </div>
    <template v-for="(chat, i) in userResponse" :key="i">
      <div class="chat chat-start">
        <div class="chat-image avatar">
          <div class="w-10 rounded-full">
            <img
              alt="Tailwind CSS chat bubble component"
              src="@/assets/img/Pi_logo.png"
            />
          </div>
        </div>
        <div class="chat-header">Sophia</div>
        <div class="chat-bubble">{{ chat.ai_response }}</div>
      </div>
      <div class="chat chat-end">
        <div class="chat-image avatar">
          <div class="w-10 rounded-full">
            <img
              alt="Tailwind CSS chat bubble component"
              src="@/assets/img/Gemini_Generated_Image_mn8yhcmn8yhcmn8y.jpg"
            />
          </div>
        </div>
        <div class="chat-header">Candidate</div>
        <div class="chat-bubble">{{ chat.user_response || "N/A" }}</div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useWebSocket } from "@vueuse/core";
const environment = useRuntimeConfig();

console.log("environment", environment);
const userResponse = ref<any>([]);
const props = defineProps<{
  callId: string;
}>();
const { status, data, close } = useWebSocket(
  `${environment.public.NUXT_APP_WSS}/${props.callId}`,
  {
    immediate: true,
    autoClose: true,
    autoReconnect: false,
    onError(ws, event) {
      console.log("error", event);
    },
  }
);
import { useMyCandidatesStore } from "@/store/candidate";
const candidateStore = useMyCandidatesStore();
watch(status, (newVal) => {
  if (newVal === "CLOSED") {
    const route = useRoute();
    const id = route.params.id as string;
    candidateStore.findOneCandidate(id);
  }
});

watch(data, (newVal) => {
  const parsedData = JSON.parse(newVal).data;
  if (Array.isArray(parsedData)) {
    userResponse.value.push(...parsedData);
  } else if (
    typeof parsedData === "object" &&
    parsedData !== null &&
    Object.keys(parsedData).length > 0
  ) {
    userResponse.value.push(parsedData);
  }
});
</script>

<style></style>
