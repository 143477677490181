<template>
  <div class="tz-demo-wrap">
    <div class="dp-container-wrap">
      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Date and
        Time:</label>
      <VueDatePicker v-model="activeJobDescription.date" time-picker-inline :is-24="false"
        :dark="colorMode.value === 'dark'" :light="colorMode.value === 'light'">
        <template #trigger>
          <input type="text" placeholder="Add a question" class="input input-bordered w-full"
            v-model="activeJobDescription.date" />
        </template>
      </VueDatePicker>
    </div>
    <div class="mt-4">
      <label for="time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Choose the timezone:</label>
      <select id="timezones" name="timezone" v-model="activeJobDescription.timezone"
        class="select select-bordered w-full">
        <option v-for="timezone in timezones" :value="timezone.value">{{ timezone.name }} </option>
      </select>
    </div>
    <!-- add new questions -->
    <div class="add-more mt-4">
      <label>Add more questions?</label>
      <template v-if="newQuestions.length > 0">
        <div class="flex flex-wrap gap-2 mt-3">
          <div v-for="(question, index) in newQuestions" :key="index"
            class="flex items-center gap-2 bg-base-100/50 p-2 rounded-lg hover:bg-base-100">
            <span>{{ question }}</span>
            <button class="btn btn-circle btn-outline btn-xs" @click="handleRemoveQuestionButtonClick(question, index)">
              <Icon name="uil:times" size="20" />
            </button>
          </div>
        </div>
      </template>
      <div class="add-new flex gap-3 items-center mt-4">
        <input class="input input-bordered join-item w-11/12" v-model="newQuestiontext"
          placeholder="Add a new question" />
        <button className="btn btn-circle btn-outline btn-sm" @click="addNewQuestion">
          <Icon name="uil:plus" size="20" />
        </button>
      </div>
    </div>
    <div class="mt-4 text-right">
      <button class="btn btn-primary" @click="handleSavejobDescriptionClick">Schedule Call</button>
    </div>
  </div>
  <!-- confirm delete Modal -->
  <Teleport to="body">
    <TransitionRoot appear :show="isRevealed" as="template">
      <Dialog as="div" @close="cancel" class="relative z-10">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
          leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-slate-400/55 dark:bg-slate-900/65 backdrop-blur-sm" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-green-100 p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  Schedule a new Call
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-black">
                    Are you sure you want to scheduled call at {{ formatDate(activeJobDescription.date) }} timezone?
                  </p>
                </div>

                <div class="mt-4 float-right">
                  <button type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    @click="confirm(false)">
                    No, Go back
                  </button>
                  <button type="button"
                    class="inline-flex ml-3 justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    @click="confirm(true)">
                    Yes, Please schedule
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>
<script lang="ts" setup>

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
import { useMyAppStore } from '~/store/app';
import { z } from 'zod';
import useValidation from '~/composables/useValidation';
import { useMyJobStore } from "~/store/job";
import { useMyCandidatesStore } from "~/store/candidate";
import { formatDate, formatDateForInput } from '~/utils/timeZoneConvertor';
const candidateStore = useMyCandidatesStore();
const colorMode = useColorMode();
const jobStore = useMyJobStore();
interface Props {
  hash_id?: string
}
const prop = withDefaults(defineProps<Props>(), {
  hash_id: ""
})
const newQuestions = reactive<string[]>([]);

type ErrorType = {
  messages: string[];
  isError: boolean;
};
const errs = reactive<ErrorType>({
  messages: [],
  isError: false,
});

const addNewQuestion = () => {
  if (newQuestiontext.value) {
    newQuestions.push(newQuestiontext.value);
    newQuestiontext.value = "";
  }
};

const activeJobDescription = reactive<any>({
  timezone: 'GMT+0530',
  date: new Date(),
  questions: [],
});
const addNewQuestionInputVisible = ref<boolean>(true);
const newQuestiontext = ref<string>("");
const timezones = computed<any>(() => jobStore.timezones);

// const 
const handleRemoveQuestionButtonClick = (
  question: string,
  questionIndex: number
) => {
  activeJobDescription.questions =
    activeJobDescription.questions.filter(
      (item: any, index: number) => item !== question && questionIndex !== index
    );
};

// validation
const validationSchema = z.object({
  date: z.string().min(1, 'Date is required'),
  timezone: z.string().min(1, 'Timezone is required'),
});

const { validate, errors, isValid, getError, scrolltoError } = useValidation(validationSchema, activeJobDescription, {
  mode: 'lazy',
});
const formSubmitting = ref<boolean>(false);

const {
  isRevealed,
  reveal,
  confirm,
  cancel,
} = useConfirmDialog();

const emit = defineEmits(["call-scheduled"]);

const handleSavejobDescriptionClick = async () => {
  const { data } = await reveal();
  if (!data) return;
  const timeConverted = formatDate(activeJobDescription.date);
  console.log(timeConverted);
  const payload = {
    questions: [...newQuestions],
    scheduled_at: formatDateForInput(activeJobDescription.date),
    timezone: activeJobDescription.timezone,
    hash_id: prop.hash_id
  };
  const appStore = useMyAppStore();
  const res = await candidateStore.scheduleCall(payload);
  if (res.status === 200) {
    emit("call-scheduled")
    appStore.success({
      text: 'Call scheduled successfully!',
    })
  } else {
    appStore.error({
      text: 'Something went wrong while scheduling the call',
    })
  }
};

</script>
<style>
.modal-box {
  min-height: 75lvh;
}
</style>