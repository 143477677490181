<template>
  <NuxtLayout name="auth">
    <div class="bg-base-200 min-h-[40vh] relative -z-0">
      <div class="hero-content text-center">
        <img src="@/assets/img/10619.jpg" alt="Background Image"
          class="absolute z-0 inset-0 w-full h-full object-cover overflow-hidden">
      </div>
    </div>
    <header class="container mx-auto bg-base-200 shadow-sm -mt-[20vh] relative z-10 w-full  p-3">
      <div class="grid grid-cols-4 px-8 py-6">
        <div class="col-span-3">
          <span class="text-md font-fold bg-base-content text-base-100 px-2 py-1 rounded">
            {{ candidate?.job_title }}
          </span>
          <h2 class="flex text-6xl font-bold m">{{ candidate?.name }}
            <button @click="handleEditModal">
              <Icon name="mdi-pencil" size="20" class="ml-2 cursor-pointer" />
            </button>
          </h2>
          <p class="flex items-center text-sm opacity-50">
            <Icon name="material-symbols-light:location-on" size="20"></Icon> {{ candidate?.current_location }}
          </p>
          <p class="text-sm opacity-50 flex items-center mt-1">
            <Icon name="bx:bx-time-five" size="16"></Icon> {{ candidate?.total_year_of_experience }}
          </p>
          <p v-if="candidate?.last_job_role" class="text-sm opacity-50 flex items-center mt-1">
            <Icon name="bx:bx-time-five" size="16"></Icon>Last job: {{ candidate?.last_job_role }}
          </p>
          <div class="flex items-center mt-2">
            <div class="flex items-center">
              <span v-for="(education, index) in candidate?.education" class="text-sm opacity-50">
                <Icon name="carbon:education" size="16"></Icon> {{ education }}
              </span>
            </div>
          </div>
          <!-- contact address -->
          <div class="flex items-center mt-2">
            <div class="flex items-center justify-center">
              <span v-if="candidate?.number">
                <Icon name="bx:bx-phone" size="16"></Icon>
                <span class="text-sm opacity-50 ml-1">
                  {{ candidate?.number }}
                </span>
              </span>
              <span v-if="candidate?.email">
                <Icon name="radix-icons:dot-filled" size="16" class="mx-3"></Icon>
                <span class="text-sm opacity-50">
                  <Icon name="bx:bx-envelope" size="16"></Icon>
                  {{ candidate?.email }}
                </span>
              </span>
            </div>

          </div>
          <!-- <button class="btn btn-primary ml-2 mt-4" @click="scheduleCall">
            <Icon name="bx:bx-message-rounded-dots" size="32"></Icon> Schedule Interview
          </button> -->
        </div>
        <div class="col-span-1 bg-base-100 w-full border-l-primary border-l-4">
          <div class="py-12 px-8">
            <div class="ai-facts">
              <span class="text-md font-fold bg-base-content text-base-100 px-2 py-1 rounded">
                <Icon name="ph:sparkle-duotone" size="16" />
                AI Match
              </span>
            </div>
            <ul class="mt-6">
              <li v-for="fact in aiFacts?.general" :key="fact.skill_name" class="mb-1">
                <div class="flex justify-between items-center">
                  <span v-if="fact.match" class="text-green-500 text-md flex items-center">
                    <Icon v-if="fact.match" name="material-symbols:check-circle-rounded" size="20" class="mr-2">
                    </Icon>{{ fact.skill_name }}
                  </span>
                  <span v-else class="text-red-500 text-md flex items-center">
                    <Icon name="ic:baseline-cancel" class="mr-2" size="20"></Icon>{{ fact.skill_name
                    }}
                  </span>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    
    <main class="container mx-auto pt-8 mb-40">
      <MazTabs>
        <MazTabsBar :items="tabs" />
        <MazTabsContent>
          <MazTabsContentItem :tab="1" class="maz-py-4">
            <div class="grid grid-cols-4 gap-5">
              <!-- General info -->
              <div class="col-span-3">
                <SectionUserHealth :facts="aiFacts" :score="candidate?.score" />
                <section class="bg-base-200/90 w-full rounded-lg shadow-sm px-8 py-6 mt-5">
                  <!-- files to download -->
                  <div class="" id="files_download">
                    <h2 class="text-xl font-bold">Candidate files</h2>
                    <button class="btn btn-outline btn-sm mt-2" @click="handleDownloadResume(candidate?.hash_id)">
                      <template v-if="isFileDownloading">
                        <span class="loading loading-spinner loading-md mr-2"></span> Downloading...
                      </template>
                      <template v-else>
                        <Icon name="vscode-icons:file-type-pdf2" size="16" class="mr-2"></Icon> {{ candidate?.name }}
                      </template>
                    </button>
                  </div>
                  <div class="mt-5" id="certificates">
                    <h2 class="text-xl font-bold">Licenses and Certificates</h2>
                    <ul
                      class="overflow-y-scroll relative mx-auto  ring-black/5 flex flex-col divide-y dark:divide-slate-200/5">
                      <li v-for="certificate in candidate?.certificates"
                        class="text-slate-900 text-sm font-medium dark:text-slate-200 py-4">
                        <div class="flex items-center">
                          <Icon name="mdi:certificate" class="mr-2" /> <span>{{ certificate }}</span>
                        </div>
                      </li>
                    </ul>
  
                  </div>
                  <!-- Candidate Experience -->
                  <div id="candidate_exp" class="mt-5">
                    <h2 class="text-xl font-bold">Experience</h2>
                    <ol class="relative border-s mt-5 border-gray-400 dark:border-gray-700">
                      <li class="mb-10 ms-4" v-for="companies in candidate?.previous_companies">
                        <div
                          class="absolute w-3 h-3 bg-gray-400 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700">
                        </div>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">{{ companies.company_name }}</h3>
                        <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{
                          companies.start_date }}
                          - {{ companies.end_date }} {{ companies.tenure_in_company }}</time>
                      </li>
                    </ol>
                  </div>
                </section>
                <section class="">
                  <div class="grid grid-cols-2 gap-5">
                    <!-- skills -->
                    <div class="bg-base-200/90 w-full rounded-lg shadow-sm px-8 py-6 mt-5">
                      <h2 class="text-xl font-bold">Skills</h2>
                      <ul
                        class="overflow-y-scroll relative mx-auto  ring-black/5 flex flex-col divide-y dark:divide-slate-200/5">
                        <li v-for="skill in candidate?.skills"
                          class="text-slate-900 text-md font-medium dark:text-slate-200 py-4">
                          <div class="flex justify-between items-center">
                            <span>{{ skill }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- education -->
                    <div class="bg-base-200/90 w-full rounded-lg shadow-sm px-8 py-6 mt-5">
                      <div class="flex items-center">
                        <MatchCircle :percentage="Math.ceil(Number(candidate?.score || 0))" size="small" />
                        <h3 class="ml-2">Score: {{ prepareScoreTab.overallScore }}</h3>
                      </div>
                      <ul
                        class="overflow-y-scroll relative mx-auto  ring-black/5 flex flex-col divide-y dark:divide-slate-200/5">
                        <li class="text-slate-900 text-md font-medium dark:text-slate-200 py-4">
                          <div class="flex justify-between items-center">
                            <span><b>High priority skills</b>: {{ prepareScoreTab.highPrioritySkills }}</span>
                          </div>
                        </li>
                        <li class="text-slate-900 text-md font-medium dark:text-slate-200 py-4">
                          <div class="flex justify-between items-center">
                            <span><b>Low priority skills</b>: {{ prepareScoreTab.lowPrioritySkills }}</span>
                          </div>
                        </li>
                        <li class="text-slate-900 text-md font-medium dark:text-slate-200 py-4">
                          <div class="flex justify-between items-center">
                            <span><b>Good to have skills</b>: {{ prepareScoreTab.goodToHaveSkills }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
              <!-- Schedule call -->
              <div class="col-span-1">
                <section class="bg-base-200/90 w-full rounded-lg shadow-sm px-8 py-6 mt-5">
                  <h2 class="text-xl font-bold">Schedule a call</h2>
                  <ScheduleCall :hash_id="candidate?.hash_id"  @call-scheduled="handleCallScheduled" />
                </section>
              </div>
            </div>
          </MazTabsContentItem>
          <MazTabsContentItem :tab="2" class="maz-py-4">
            <CallSchedule :candidate="candidate" :updateCandidateCallDetails="updateCandidateCallDetails" />
          </MazTabsContentItem>
        </MazTabsContent>
      </MazTabs>
    </main>
    <!-- Edit candidate Modal -->
    <Teleport to="body">
      <TransitionRoot appear :show="isEditShown" as="template">
        <Dialog as="div" @close="handleCloseEditModal" class="relative z-10">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
            leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-slate-400/55 dark:bg-slate-900/65 backdrop-blur-sm" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95">
                <DialogPanel
                  class="w-full max-w-xl transform overflow-hidden bg-base-100 rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <!-- close button -->
                  <div @click="handleCloseEditModal" class="absolute top-2 right-2">
                    <button class="btn btn-ghost btn-sm btn-circle hover:rotate-90 transition-transform">
                      <Icon name="grommet-icons:close" size="16" />
                    </button>
                  </div>
                  <DialogTitle as="h3" class="text-3xl font-bold leading-6 ">
                    Edit {{ candidate?.name }}
                  </DialogTitle>
                  <div class="mt-2">
                    <SectionEditCandidate :candidate="candidate" @on-close-modal="handleSaveEditModal" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </Teleport>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import SectionEditCandidate from "~/components/candidate/SectionEditCandidate.vue";
import { useMyCandidatesStore } from "~/store/candidate";
import { useMyAppStore } from "~/store/app";
import { type Candidate, type AISkill, type AiFact } from "~/types/candidate";
const candidateStore = useMyCandidatesStore();
import SectionUserHealth from "~/components/candidate/SectionUserHealth.vue";
import ScheduleCall from "~/components/ScheduleCall.vue";
const candidate = ref<Candidate | null>(null);
import CallSchedule from "~/components/candidate/CallSchedule.vue";
const isLoading = ref<boolean>(false);
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
const getOneCandidate = async () => {
  const id = useRoute().params.id as string;
  const res = await candidateStore.findOneCandidate(id);
  if (res.status !== 200) {
    createError({ statusCode: res.status, statusMessage: res.error })
  }
  candidate.value = res.data;
}

watchEffect(() => {
  candidate.value = candidateStore.candidate
})

const addSkillsToFacts = (skills: AISkill[]) => {
  return skills.map(skill => {
    return { skill_name: skill.skill_name, match: skill.match, reasoning: skill.reasoning };
  });
};

const aiFacts = ref<AiFact | null>(null);
const generateAiFacts = () => {
  const { education_match, jobdescription_match, location_match, experience_match, required_skills, good_to_have_skills } = candidate.value as Candidate;

  const facts: AiFact = {
    general: [
      { skill_name: 'Education Match', match: education_match, reasoning: null },
      { skill_name: 'Job Description Match', match: jobdescription_match, reasoning: null },
      { skill_name: 'Location Match', match: location_match, reasoning: null },
      { skill_name: 'Experience Match', match: experience_match, reasoning: null },
    ],
  };

  facts.highPriority = [];
  required_skills?.high_priority.forEach(skill => {
    facts.highPriority?.push(skill);
  });
  facts.required = [];
  required_skills?.low_priority.forEach(skill => {
    facts.required?.push(skill);
  });
  facts.goodToHave = [];
  facts.goodToHave?.push(...addSkillsToFacts(good_to_have_skills));

  facts.pros = candidate.value?.resume_pros_cons.pros.map(pro => ({
    skill_name: pro,
    match: true,
    reasoning: null,
  }));
  facts.cons = candidate.value?.resume_pros_cons.cons.map(con => ({
    skill_name: con,
    match: false,
    reasoning: null,
  }));

  return facts || null;
};
const appStore = useMyAppStore();
const isFileDownloading = ref<boolean>(false);
const handleDownloadResume = async (hashId: string | undefined) => {
  isFileDownloading.value = true;
  if (!hashId) {
    appStore.error({ text: 'No resume found' });
    isFileDownloading.value = false;
    return;
  }
  const res = await candidateStore.downloadResume(hashId);
  if (res.status !== 200) {
    appStore.error({ text: res.error || 'Error downloading resume' });
    isFileDownloading.value = false;
  } else {
    window.open(res.data, '_blank');
    isFileDownloading.value = false;
  }
};
const prepareScoreTab = computed(() => {
  const analysis = {
    highPrioritySkills: `${candidate.value?.required_skills?.high_priority?.filter(f => f.match).length}/${candidate.value?.required_skills?.high_priority?.length}`,
    lowPrioritySkills: `${candidate.value?.required_skills?.low_priority?.filter(f => f.match).length}/${candidate.value?.required_skills?.low_priority?.length}`,
    goodToHaveSkills: `${candidate.value?.good_to_have_skills?.filter(f => f.match).length}/${candidate.value?.good_to_have_skills?.length}`,
    resumeScore: `${aiFacts.value?.general.filter(f => f.match).length}/${aiFacts.value?.general.length}`,
    overallScore: ''
  }
  if ((candidate?.value?.score ?? 0) < 50) {
    analysis.overallScore = 'Not Fit';
  } else if ((candidate?.value?.score ?? 0) < 70) {
    analysis.overallScore = 'Can be Considered';
  } else if ((candidate?.value?.score ?? 0) < 90) {
    analysis.overallScore = 'Potential Fit';
  } else {
    analysis.overallScore = 'Star Candidate';
  }
  return analysis;
});

const isEditShown = ref<boolean>(false);
const handleEditModal = () => {
  isEditShown.value = true;
};
const handleCloseEditModal = () => {
  isEditShown.value = false;
};
const handleSaveEditModal = () => {
  isEditShown.value = false;
};

const updateCandidateCallDetails = ref<boolean>(false)

const handleCallScheduled = async () => {
  appStore.incrementLoading();
  await new Promise(resolve => setTimeout(resolve, 4000));

  const res = await candidateStore.findOneCandidate(candidate.value?.hash_id as string);
  // add a wait for 4 seconds
  if (res.status !== 200) {
    createError({ statusCode: res.status, statusMessage: res.error })
    appStore.decrementLoading();
  } else {
    candidate.value = res.data;
    appStore.decrementLoading();
  }
};

onMounted(() => {
  isLoading.value = true;
  getOneCandidate().then(() => {
    isLoading.value = false;
    aiFacts.value = generateAiFacts();
  });
});

const tabs = [
  {
    id: 1,
    label: 'General info',
  },
  {
    id: 2,
    label: 'Call details',
  },
];
</script>

<style scoped>
.tab-active {
  position: relative;
}

.tab-active:before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: oklch(var(--p));
}
</style>